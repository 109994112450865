import React, { useEffect } from "react"
import Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import Exporting from "highcharts/modules/exporting"

HighchartsMore(Highcharts)
Exporting(Highcharts)

/**
 * Component to render a scatter plot of the results of a patient's tests.
 * Each point in the scatter plot represents a test result, and the x and y
 * coordinates of the point represent the safety score and replacement score,
 * respectively, of the test result. The scatter plot is divided into four
 * quadrants, each representing a different combination of safety score and
 * replacement score. The top-left quadrant is the most desirable, representing
 * tests with low safety scores and low replacement scores, and the bottom-right
 * quadrant is the least desirable, representing tests with high safety scores
 * and high replacement scores. The scatter plot also includes a 'Selected' series
 * which highlights the test results that have been selected by the user.
 *
 * @param {Object} props - Component props
 * @param {Object[]} props.orders - Array of objects, each representing a test result
 * @param {Function} props.onPointClick - Function to call when a point is clicked
 * @param {Object} props.orderInfo - Object containing the patient's order information

 */
const ScatterChart = ({ orders, onPointClick, orderInfo }) => {
  const isClickDisabled = orderInfo.order_status === "CONFIRMED"

  /** Function that add a ramdon offset to the x and y values in the chart  */
  const addRandomOffset = (data) =>
    data.map(([x, y]) => [
      Math.abs(x + Math.random() / 2),
      Math.abs(y + Math.random() / 2),
    ])

  useEffect(() => {
    const xDataMax = Math.max(...orders.map((order) => order.x))
    const yDataMax = Math.max(...orders.map((order) => order.y))

    const xMax = Math.max(xDataMax, orderInfo.threshold_safety_score)
    const yMax = Math.max(yDataMax, orderInfo.threshold_replacement_score)

    /** Map the order and sorted by bp and ep data */
    const bpRawData = orders
      .filter((order) => order.din_profile === "BP")
      .map((order) => [order.x, order.y])

    const epRawData = orders
      .filter((order) => order.din_profile === "EP")
      .map((order) => [order.x, order.y])

    //Renders the bp Data with the random offset
    const bpData = addRandomOffset(bpRawData).map(([x, y], index) => ({
      result_id: orders[index].order_results_id,
      x,
      y,
      din: orders[index].din,
      abo: orders[index].aborh,
      marker: {
        radius: orders[index].selected ? 12 : 8,
        fillColor: orders[index].selected
          ? "rgba(227, 84, 41, 0.6)"
          : "rgba(255, 143, 111, 0.6",
        lineWidth: 2,
        lineColor: "var(--secondary-color)",

        symbol: orders[index].selected ? "diamond" : "square",
      },
    }))

    //Renders the EP Data with the random offset
    const epData = addRandomOffset(epRawData).map(([x, y], index) => ({
      result_id: orders[index].order_results_id,
      x,
      y,
      din: orders[index].din,
      abo: orders[index].aborh,
      marker: {
        radius: orders[index].selected ? 12 : 8,
        fillColor: orders[index].selected
          ? "rgba(227, 84, 41, 0.6)"
          : "rgba(134, 152, 179, 0.6)",
        lineWidth: 1,
        lineColor: "var(--primary-color)",
        symbol: orders[index].selected ? "diamond" : "circle",
      },
    }))

    Highcharts.chart("container", {
      chart: {
        type: "scatter",
        plotBorderWidth: 1,
        zoomType: "xy",
      },
      title: {
        text: `Matched Results for Patient: ${orderInfo.patient_key}<br/><span style="font-size: 12px; color: gray;">Lower values tend to be preferable</span><br/><span style="font-size: 12px; color: gray;">Drag to zoom in and out</span>`,
      },
      xAxis: {
        title: {
          text: 'Safety Score <br/><span style="font-size: 12px; color: gray;">Higher values have more potencial risk</span>',
        },
        min: 0,
        max: xMax,
        //Renders the safety score threshold and the replacement score threshold
        plotLines: [
          {
            color: "var(--secondary-color)",
            width: 2,

            value: orderInfo.threshold_safety_score,
            zIndex: 5,
            label: {
              text: "Safety Score Threshold",
              align: "right",
              verticalAlign: "middle",
              style: {
                color: "var(--primary-color)",
              },
            },
          },
        ],
      },
      yAxis: {
        title: {
          text: 'Replacement Score<br/><span style="font-size: 12px; color: gray;">Higher values are harder to replace</span>',
        },
        min: 0,
        max: yMax,
        plotLines: [
          {
            color: "var(--primary-color)",
            width: 2,
            value: orderInfo.threshold_replacement_score,
            zIndex: 5,
            label: {
              text: "Replacement Score Threshold",
              align: "left",
              style: {
                color: "var(--secondary-color)",
              },
            },
          },
        ],
      },
      //Renders the tooltip, the hover that show the din profile, din and aborh
      tooltip: {
        formatter: function () {
          return `<strong>Result Index:</strong> ${this.point.result_id}<br>
                  <strong>Safety Score:</strong> ${Math.round(this.x)}<br>
                  <strong>Replacement Score:</strong> ${Math.round(
                    this.y
                  )}<br><br>
                  <strong>DIN Profile:</strong> ${this.series.name}<br>
                  <strong>DIN:</strong> ${this.point.din}<br>
                  <strong>ABORh:</strong> ${this.point.abo}`
        },
      },
      series: [
        {
          name: "BP",
          marker: {
            symbol: "circle",
            fillColor: "rgba(255, 143, 111, 0.6",
            lineWidth: 2,
            lineColor: "var(--secondary-color",
          },
          data: bpData,
          point: {
            events: {
              click: function () {
                if (!isClickDisabled) {
                  onPointClick(this.result_id)
                }
              },
            },
          },
        },
        {
          name: "EP",
          marker: {
            symbol: "circle",
            fillColor: "rgba(190, 217, 255, 0.6)",
            lineWidth: 2,
            lineColor: "var(--primary-color)",
          },
          data: epData,
          point: {
            events: {
              click: function () {
                if (!isClickDisabled) {
                  onPointClick(this.result_id)
                }
              },
            },
          },
        },
      ],
      exporting: {
        enabled: true,
        url: "https://export.highcharts.com/",
        fallbackToExportServer: false,
        buttons: {
          contextButton: {
            menuItems: [
              "printChart",
              "separator",
              "downloadPNG",
              "downloadPDF",
              "separator",
              "viewFullscreen",
            ],
          },
        },
      },
    })
  }, [orders, onPointClick])

  return (
    <div id="container" className="mx-4 my-4" style={{ height: "40rem" }} />
  )
}

export default ScatterChart
