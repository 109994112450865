import React from "react"
import { Route, Routes } from "react-router-dom"
import _ from "lodash"
import { routesDef } from "./routesDef"

export default function RoutesBuilder() {
  /**
   * Given an array of route definitions, returns an array of Route components
   *
   * @param {Array<{path: string, element: React.ReactElement}>} routesDef
   *  Array of route definitions, where each route definition has a path and an element
   *  property. The element property is expected to be a valid React element.
   *
   * @returns {Array<React.ReactElement>}
   *  Array of Route components
   */
  const getRouteElements = (routesDef) => {
    return _.map(routesDef, (route, i) => {
      return <Route key={i} path={route.path} element={route.element} />
    })
  }

  /**
   * Returns a Routes component with all the route elements defined in the routesDef
   * array
   *
   * @returns {React.ReactElement}
   *  A Routes component with all the route elements defined in the routesDef
   *  array
   */
  function handleRoutes() {
    const routeList = getRouteElements(routesDef)
    return <Routes>{routeList}</Routes>
  }

  return <>{handleRoutes()}</>
}
