import React from "react"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"

import PropTypes from "prop-types"

const ResetPasswordDialog = ({ hide, onResetPassword, setRecoverEmail }) => (
  <div
    className="flex flex-column px-8 py-5 gap-4"
    style={{
      borderRadius: "12px",
      backgroundColor: "var(--primary-color)",
    }}
  >
    <div className="inline-flex flex-column gap-2 align-items-center">
      <label htmlFor="username" className="text-primary-50 font-semibold">
        Enter your email, and we'll send you a link to easily recover your
        password.
      </label>
      <InputText
        id="username"
        label="Username"
        className="bg-white-alpha-20 border-none p-3 text-primary-50 w-full"
        onChange={(e) => setRecoverEmail(e.target.value)}
      />
    </div>

    <div className="flex align-items-center gap-2">
      <Button
        label="Reset Password"
        onClick={onResetPassword}
        text
        className="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
      />
      <Button
        label="Cancel"
        onClick={hide}
        text
        className="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
      />
    </div>
  </div>
)

ResetPasswordDialog.propTypes = {
  hide: PropTypes.func,
  onResetPassword: PropTypes.func,
  setRecoverEmail: PropTypes.func,
}

export default ResetPasswordDialog
