import React from "react"
import { DNA, ColorRing } from "react-loader-spinner"
import { RotatingLines } from "react-loader-spinner"

/**
 * A loader that displays a DNA helix animation with a height and width of 160px.
 * The component is wrapped in a section element with a centered DNA helix and
 * a heading that says "Forming the pixels on your screen..."
 *
 * @returns {React.ReactElement} A react section element with a DNA helix animation
 * and a centered heading.
 */
export function LoaderMedium() {
  return (
    <section>
      <div className="flex flex-column flex-wrap justify-content-center align-content-center ">
        <DNA
          visible={true}
          height="160"
          width="160"
          ariaLabel="dna-loading"
          wrapperStyle={{}}
          wrapperClass="dna-wrapper"
        />
      </div>
      <h2>Forming the pixels on your screen...</h2>
    </section>
  )
}

/**
 * A loader that displays a rotating color ring animation with a height and width of 60px.
 * The component is wrapped in a section element with a centered rotating color ring and
 * no other content.
 *
 * @returns {React.ReactElement} A react section element with a rotating color ring animation
 */
export function LoaderRotation() {
  return (
    <section>
      <div className="flex flex-column flex-wrap justify-content-center align-content-center ">
        <ColorRing
          visible={true}
          height="60"
          width="60"
          ariaLabel="color-ring-loading"
          wrapperStyle={{}}
          wrapperClass="color-ring-wrapper"
          colors={["#14414c", "#8c3319", "#d8d3b2", "#14414c", "#8c3319"]}
        />
      </div>
    </section>
  )
}
