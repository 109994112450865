/**
 * Pushes an event to Google Analytics using the dataLayer.
 *
 * If an event and user are provided, the function will push the event
 * and user_id to the dataLayer. If either the event or user are not
 * provided, the function will do nothing.
 *
 * @param {Object} args - The arguments object.
 * @param {string} args.event - The name of the event to track.
 * @param {Object} [args.payload] - The payload to track with the event.
 * @param {Object} args.user - The user object. The user_id will be
 *     extracted from this object.
 */
export default function gaTracking(args) {
  const { event, payload, user } = args
  if (event && user) {
    window.dataLayer.push({
      event: event,
      user_id: user.user_id,
      organization_id: user.organization_id,
      user_role: user.user_role_value,
      ...payload,
    })
  } else {
    return
  }
}
