import { Navigate } from "react-router-dom"

import ROUTES from "./routesConstants"
import Home from "../pages/homepage/Home"
import Login from "../pages/login/login"
import Form from "../pages/Form/Form"
import Results from "../pages/Results/Results"
import Confirmation from "../pages/Confirmation/Confirmation"
import OrderCompleted from "../pages/Results/OrderCompleted"
import Error from "../pages/Error/Error"
import FailedOrder from "../pages/Results/FailedOrder"

const routesDef = [
  {
    path: "/",
    element: <Navigate to={ROUTES.Home} replace />,
  },
  {
    path: ROUTES.Home,
    element: <Home />,
  },
  {
    path: ROUTES.Login,
    element: <Login />,
  },
  {
    path: ROUTES.Form,
    element: <Form />,
  },
  {
    path: ROUTES.Results,
    element: <Results />,
  },
  {
    path: ROUTES.Confirmation,
    element: <Confirmation />,
  },
  {
    path: ROUTES.Order,
    element: <Results />,
  },
  {
    path: ROUTES.OrderCompleted,
    element: <OrderCompleted />,
  },
  {
    path: ROUTES.Error,
    element: <Error />,
  },
  {
    path: ROUTES.FailedOrder,
    element: <FailedOrder />,
  },
]

export { routesDef }
