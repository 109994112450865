import React from "react"
import PropTypes from "prop-types"
//PR
import { Tag } from "primereact/tag"
/**
 * Given a status text, returns a colored tag with the given text.
 *
 * Supported status texts are:
 * - NEW: Blue
 * - FAILED: Red
 * - MATCHED: Green
 * - SELECTED: Yellow
 * - CONFIRMED: Dark yellow
 *
 * @param {string} text - The status text to convert to a colored tag.
 * @returns {React.ReactElement} - A React element representing the colored tag.
 */
export default function colorSelector(text) {
  let color
  let fontColor
  switch (text) {
    case "NEW":
      color = "#C0C7FF"
      fontColor = "#4552FF"
      break
    case "FAILED":
      color = "#F9D5D3"
      fontColor = "#AD4256"
      break
    case "MATCHED":
      color = "#DBF9D9"
      fontColor = "#449335"
      break
    case "SELECTED":
      color = "#FDDCB0"
      fontColor = "#A06E2F"
      break
    case "CONFIRMED":
      color = "#FDF5D8"
      fontColor = "#99692A"
      break
    default:
  }

  return (
    <div>
      <Tag
        value={text}
        className="xl:w-6 w-8"
        style={{ backgroundColor: color, color: fontColor }}
        rounded
      />
    </div>
  )
}

colorSelector.propTypes = {
  severity: PropTypes.number, // Cambié a number para coincidir con su uso
  text: PropTypes.string,
}
