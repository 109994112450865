import React from "react"
import PropTypes from "prop-types"
import { Dropdown } from "primereact/dropdown"
import { LoaderRotation } from "../../../components/Loader/loaders"
/**
 * PatientDropdown is a reusable React component that renders a Primereact
 * Dropdown component with a list of patients. It also handles the loading
 * state when the list of patients is being fetched.
 *
 * @param {array} patients - The list of patients to be rendered in the dropdown.
 * @param {boolean} loadingPatients - Whether the list of patients is being
 *                                    fetched or not.
 * @param {string} selectedPatient - The currently selected patient in the
 *                                   dropdown.
 * @param {function} setSelectedPatient - A function to set the selected patient
 *                                        in the dropdown.
 * @param {object} formik - The Formik context object to update the form state
 *                          when the selected patient changes.
 */
const PatientDropdown = ({
  patients,
  loadingPatients,
  selectedPatient,
  setSelectedPatient,
  formik,
}) => {
  if (loadingPatients) {
    return (
      <div className="flex align-items-center gap-2">
        <div className="flex flex-column gap-0">
          <p className="m-0">Loading Patients...</p>
          <p className="m-0 text-xs">This may take a few seconds</p>
        </div>
        <LoaderRotation />
      </div>
    )
  }

  if (patients.length === 0) {
    return (
      <div className="flex flex-column">
        <Dropdown
          className="custom-search-dropdown"
          options={patients}
          optionLabel="label"
          optionValue="value"
          filter
          onChange={(e) => {
            setSelectedPatient(e.value)
            formik.setFieldValue("patient_key", e.value)
          }}
          value={selectedPatient}
          placeholder="No patients available"
        />
      </div>
    )
  }

  return (
    <div className="flex flex-column">
      <div className="flex flex-column ml-8">
        <Dropdown
          className="custom-search-dropdown"
          options={patients}
          optionLabel="label"
          optionValue="value"
          filter
          onChange={(e) => {
            setSelectedPatient(e.value)
            formik.setFieldValue("patient_key", e.value)
          }}
          value={selectedPatient}
          placeholder="Select a patient"
        />
        {formik.touched.patient_key && formik.errors.patient_key ? (
          <div className="p-error">{formik.errors.patient_key}</div>
        ) : null}
      </div>
    </div>
  )
}

PatientDropdown.propTypes = {
  patients: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  loadingPatients: PropTypes.bool.isRequired,
  selectedPatient: PropTypes.string,
  setSelectedPatient: PropTypes.func.isRequired,
  formik: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    touched: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
}

PatientDropdown.defaultProps = {
  selectedPatient: null,
}

export default PatientDropdown
