import React from "react"
//Helpers
import parseABORh from "../../../Helpers/parseABORh"

//Images
import patient from "../../../images/patient.png"
import blood from "../../../images/blood-drop.png"
import facility from "../../../images/Facility.png"
import Calendar from "../../../images/Calendar.png"
import bandage from "../../../images/bandage.png"

//PR
import { Card } from "primereact/card"
import { Image } from "primereact/image"

/**
 * A component that displays a dashboard of patient information, including
 * the patient's ID, ABO Rh, facility, order ID, order date and time, and
 * clinical condition.
  
 */
export default function Dashboard({ orders }) {
  /**
   * Given a clinical condition code, returns a human-readable string
   * describing the condition.
   * @param {string} clinical_conditions - one of "SCD", "R", "AB", or empty
   * @returns {string} - a human-readable name for the condition
   */
  const clinicalCondition = (clinical_conditions) => {
    switch (clinical_conditions) {
      case "SCD":
        return "Sickle Cell Disease"
      case "R":
        return "Routine"
      case "AB":
        return "Alloantybody"
      default:
        return "No Clinical Condition"
    }
  }

  return (
    <div className="mb-4">
      <section className="flex flex-column gap-2 my-2 mx-4 flex-wrap ">
        <div
          id="PatientCards"
          className="flex flex-row flex-wrap gap-2 justify-content-around w-full"
        >
          <Card
            className="   flex-grow-1  px-4 border-round dashboard-card"
            style={{ flexBasis: "200" }}
          >
            <div className="flex justify-content-between align-items-start xl:align-items-center p-0 ">
              <div className="p-0  flex flex-column align-items-start ">
                <h4 className="">Patient ID:</h4>
                <h5 className=" ">{orders.patient_key}</h5>
              </div>
              <Image src={patient} width="40" height="40" className="mb-5rem" />
            </div>
          </Card>

          <Card
            className="    flex-grow-1	 px-4 border-round dashboard-card"
            style={{ flexBasis: "200" }}
          >
            <div className="flex justify-content-between  align-items-start  xl:align-items-center">
              <div className="p-0  flex flex-column align-items-start ">
                <h4 className="">ABO Rh:</h4>
                <h5 className=" ">{orders.patient_aborh || "Not Available"}</h5>
              </div>
              <Image src={blood} width="25" height="30" />
            </div>
          </Card>

          <Card
            className="   px-4  border-round flex-grow-1 min-w-15rem dashboard-card"
            style={{ flexBasis: "200" }}
          >
            <div className="flex gap-2 justify-content-between align-items-start ">
              <div className="flex flex-column">
                <h5 className="">{orders.facility_name}</h5>
              </div>
              <Image src={facility} width="40" height="45" />
            </div>
          </Card>

          <Card
            className="   flex-grow-1 px-4 border-round dashboard-card"
            style={{ flexBasis: "200" }}
          >
            <div className="flex justify-content-between  align-items-start gap-4 xl:align-items-center  xl:gap-6  min-w-10rem xl:-mt-2">
              <div className="p-0  flex flex-column ">
                <h5>{orders.order_id}</h5>
                <h5 className="">
                  {new Date(orders.order_datetime).toLocaleDateString("en-US")}{" "}
                  <br />
                  {new Date(orders.order_datetime).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </h5>
              </div>
              <Image src={Calendar} width="40" height="40" />
            </div>
          </Card>

          <Card
            className=" 	flex-grow-1 px-4 border-round dashboard-card"
            style={{ flexBasis: "200" }}
          >
            <div className="flex justify-content-between align-items-start xl:align-items-center p-0 ">
              <div className=" p-0  flex flex-column">
                <h4 className="">Clinical Condition:</h4>
                <h5 className=" max-w-15rem">
                  {clinicalCondition(orders.clinical_conditions)}
                </h5>
              </div>
              <Image src={bandage} width="40" height="40" />
            </div>
          </Card>
          <Card
            className="   	flex-grow-1 px-4 border-round dashboard-card"
            style={{ flexBasis: "200" }}
          >
            <div className="flex justify-content-between p-0 align-items-start xl:align-items-center">
              <div className=" p-0  flex flex-column">
                <h4 className="">
                  Blood Group <br></br> Units:
                </h4>
                <h5 className=" ">{orders.order_results_count}</h5>
              </div>
              <i
                className="pi pi-clipboard"
                style={{ fontSize: "2.5rem", color: "#ffff" }}
              />
            </div>
          </Card>
        </div>
      </section>
      {orders.antigen_compatibility_values && (
        <Card className="    px-4 border-round mx-4  ">
          {/*
            This section displays the Antigen Negative Compatibility Values
            for the order. This is a list of values that are compatible with
            the patient's antigens. This is only displayed if the order has
            antigen compatibility values.
          */}
          <div className="flex justify-content-between gap-8 p-0">
            <div className="p-0 gap-2 flex flex-column">
              <h4 className="">Antigen Negative Compatibility Values:</h4>
              <h5 className=" ">{orders.antigen_compatibility_values}</h5>
            </div>
            <i
              className="pi pi-check-circle"
              style={{ fontSize: "2.5rem", color: "#ffff" }}
            />
          </div>
        </Card>
      )}
    </div>
  )
}
