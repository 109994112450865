import React from "react"
import { Link } from "react-router-dom"
import ROUTES from "../../components/routesConstants"

import { BreadCrumb } from "primereact/breadcrumb"
const home = {
  label: (
    <Link to={ROUTES.Home} style={{ textDecoration: "none", color: "inherit" }}>
      Home
    </Link>
  ),
}
const breadcrumbItems = [{ label: "Order Completed" }]

export default function OrderCompleted() {
  return (
    <div>
      <BreadCrumb model={breadcrumbItems} home={home} />
      <div
        className="my-6 flex flex-column gap-3 mx-4 main-content"
        style={{ minHeight: "61.5vh" }}
      >
        <h2>Your order has been submitted</h2>
        <h4> Soon your order will be processed</h4>
        <p>
          Click <Link to={ROUTES.Home}>here</Link> to go back to the home page
        </p>
      </div>
    </div>
  )
}
