import React from "react"
import { Link } from "react-router-dom"
import ROUTES from "../../components/routesConstants"
//PR
import { BreadCrumb } from "primereact/breadcrumb"

const home = {
  label: (
    <Link to={ROUTES.Home} style={{ textDecoration: "none", color: "inherit" }}>
      Home
    </Link>
  ),
}
const breadcrumbItems = [{ label: "Failed Order" }]

export default function FailedOrder() {
  return (
    <div>
      <BreadCrumb model={breadcrumbItems} home={home} />
      <div className="my-6 flex flex-column gap-4 mx-4">
        <h2>Failed Order</h2>
        <h4>
          No donor blood units found for this order. Please create a new order
          with the same patient id after the donor inventory is refreshed.
        </h4>
        <p className="m-0">
          Click <Link to={ROUTES.Home}>here</Link> to go back to the home page
        </p>
      </div>
    </div>
  )
}
