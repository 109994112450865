import React from "react"
import { Link } from "react-router-dom"

export default function Footer() {
  return (
    <footer
      className="footer"
      // style={{ background: "#353535", color: "white" }}
    >
      <div>
        <h4 className="my-5">
          © 2024 {""}
          <Link
            className="no-underline text-white"
            to="https://www.lantanagroup.com/"
            target="_blank"
          >
            Lantana Consulting Group
          </Link>
          : Transforming healthcare through health information. All Rights
          Reserved
        </h4>
      </div>
    </footer>
  )
}
