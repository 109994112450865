import React, { useEffect, useState } from "react"
import { auth } from "../firebase"
import Header from "./Header/Header"
import Footer from "../layout/Footer/Footer"
import { useLocation } from "react-router-dom"

/**
 * A layout component that wraps the main content of the app
 * with a header and a footer if the user is authenticated.

 */
export default function Layout({ children }) {
  const location = useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  /**
   * Watches for changes in the user's authentication state and updates
   * the local state accordingly.
   *
   * @returns {void}
   */
  useEffect(() => {
    /* The callback to be invoked when the user's authentication state changes. */
    const handleChange = (user) => {
      if (user) {
        setIsAuthenticated(true)
      } else {
        setIsAuthenticated(false)
      }
    }

    /*The function to be called when the effect is cleaned up. */
    const unsubscribe = auth.onAuthStateChanged(handleChange)
    return () => unsubscribe()
  }, [])

  return (
    <div className="wrapper">
      {isAuthenticated && <Header />}

      <main className="main-content main">{children}</main>
      {isAuthenticated && <Footer className="footer" />}
    </div>
  )
}
