import React from "react"
import { useNavigate, Link, useLocation } from "react-router-dom"
import ROUTES from "../../components/routesConstants"

//PR
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { BreadCrumb } from "primereact/breadcrumb"

const home = {
  label: (
    <Link to={ROUTES.Home} style={{ textDecoration: "none", color: "inherit" }}>
      Home
    </Link>
  ),
}
const breadcrumbItems = [{ label: "Order Completed" }]

/**
 * A page that displays the results of a completed order.
 *
 * This page displays a table of the results of the order, including the
 * DIN, profile, ABO Rh, safety score, replacement score, and counts of
 * each antigen. The table is sortable and filterable, and the user can
 * click on a row to view more details about the result.
 *
 * If there are no results to display, the page displays a message to the
 * user.
 */
export default function Confirmation() {
  const location = useLocation()
  const orderSelected = location.state?.orders
  const navigate = useNavigate()

  return (
    <div>
      <BreadCrumb className="-mt-2" model={breadcrumbItems} home={home} />
      <div className="my-6">
        <h2 className="ml-4">Your Order has been Confirmed</h2>
        <h4 className="ml-4 mt-2">Check the results below</h4>
      </div>
      <hr />

      <section>
        {orderSelected && orderSelected.length > 0 ? (
          <div className="my-4 p-0">
            <DataTable
              className=" mx-4"
              value={orderSelected}
              paginator
              rows={100}
            >
              <Column
                className="w-30rem text-center"
                field="order_results_id"
                header="Result Id"
              />
              <Column
                className="w-30rem"
                field="din"
                header="DIN"
                sortable
                filter
              />
              <Column
                className="w-30rem text-center"
                field="din_profile"
                header="Profile"
                sortable
                filter
              />
              <Column
                className="w-30rem text-center"
                field="aborh"
                header="ABO Rh"
                sortable
              />
              <Column
                className="w-30rem text-center"
                field="x"
                header="Safety Score"
                sortable
              />
              <Column
                className="w-30rem text-center"
                field="y"
                header="Replacement Score"
                sortable
              />
              <Column
                className="w-30rem text-center"
                field="count_ag_cceekk"
                header="CcEeKk"
                sortable
              />
              <Column
                className="w-30rem text-center"
                field="count_ag_duffy"
                header="Duffy"
                sortable
              />
              <Column
                className="w-30rem text-center"
                field="count_ag_kidd"
                header="Kidd"
                sortable
              />
              <Column
                className="w-30rem text-center"
                field="count_ag_mns"
                header="MNS"
                sortable
              />
              <Column
                className="w-30rem text-center"
                field="count_ag_extended"
                header="Extended"
                sortable
              />
            </DataTable>
          </div>
        ) : (
          <p className="mx-4">No order results available.</p>
        )}
      </section>
      <div className="flex justify-content-end my-4 mr-4">
        <Button
          className="p-button-primary"
          label="Back to Home"
          onClick={() => navigate(ROUTES.Home)}
        />
      </div>
    </div>
  )
}
