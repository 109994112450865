import React from "react"
import { Link, useLocation } from "react-router-dom"
import ROUTES from "../../components/routesConstants"
//PR
import { BreadCrumb } from "primereact/breadcrumb"

const home = {
  label: (
    <Link to={ROUTES.Home} style={{ textDecoration: "none", color: "inherit" }}>
      Home
    </Link>
  ),
}
const breadcrumbItems = [{ label: "Error" }]

/**
 * Component that renders an error message if the user navigates to an invalid URL
 * @function
 * @returns {ReactElement} - The component
 */
export default function ErrorGeneral() {
  const location = useLocation()
  /* Use of location to set the error message, if not, it uses a default msg*/
  const errorMsg =
    location.state?.error || "The page you are looking for does not exist"
  return (
    <div>
      <BreadCrumb model={breadcrumbItems} home={home} />
      <div className="my-6 flex flex-column gap-3 mx-4 ">
        <h2>Oops! Something went wrong</h2>
        <h4>{errorMsg}</h4>
        <p className="m-0">
          Click <Link to={ROUTES.Home}>here</Link> to go back to the home page
        </p>
      </div>
    </div>
  )
}
