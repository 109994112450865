import React, { useState, useRef, useEffect } from "react"
import { Formik, Form } from "formik"
import { auth } from "../../firebase"
import { useUserContext } from "../../context/userContext"
import axios from "axios"
import * as Yup from "yup"
import GLOBAL from "../../config/variables"
//Components
import ResetPasswordDialog from "./Components/ResetPasswordDialog"
//PRIMEREACT

import { Card } from "primereact/card"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import { Dialog } from "primereact/dialog"
import { Toast } from "primereact/toast"
//GA Tracking
import gaTracking from "../../Helpers/gaTracking"

//ROUTES
import ROUTES from "../../components/routesConstants"

/**
 * Validation schema for the login form.
 */
const validationSchema = Yup.object().shape({
  /**
   * Email is required
   */
  email: Yup.string().required("Email is required"),
  /**
   * Password is required
   */
  password: Yup.string().required("Password is required"),
})

/**
 * The login page component.
 *
 * This component renders a login form and displays the app's logo and name. It
 * also handles the login process and stores the user's token and information in
 * the context state.
 *
 */
export default function Login() {
  const navigate = useNavigate()
  const [authError, setAuthError] = useState(null)
  const { user, token, setToken, setUser } = useUserContext()

  const [visible, setVisible] = useState(false)
  const [recoverEmail, setRecoverEmail] = useState("")
  const toast = useRef(null)


  const gaTrackingParams = {
    event: "",
    user,
    payload: {},
  }

  useEffect(() => {
    if (!user || !token) return

   
  }, [user, token])

  /**
   * Handles the password reset request.
   *
   * @param {object} e - The event object
   *
   * This function makes a POST request to the server to send a password reset
   * link to the user's email address. If the request is successful, it displays
   * a success message and hides the password reset dialog. If the request
   * fails, it displays an error message and hides the password reset dialog.
   */
  const handleResetPassword = (e) => {
    const payload = { user_email: recoverEmail }

    axios
      .post(
        `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/users/reset-password`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setVisible(false)
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: response.data.response.message,
            life: 3000,
          })
        }
      })
      .catch((error) => {
        console.log(error)
        setVisible(false)
      })
  }


  return (
    <div className="p-8 mt-8 ">
      <div className="flex flex-column xl:flex-row justify-content-between align-items-center">
        <div className="col-6  flex flex-column justify-content-center mt-7 animate-slide-up ">
          <h2 className="m-0 lg:text-left text-center">Welcome to</h2>
          <div className="flex align-items-center justify-content-center lg:gap-5">
            <div
              className="shadow-2"
              style={{
                width: "4rem",
                height: "4rem",
                minWidth: "64px",
                minHeight: "64px",
                backgroundColor: "var(--secondary-color)",
                borderRadius: "50%",
              }}
            ></div>

            <h1 className="text-center m-0">MatchLink</h1>
          </div>
          <hr size="8px" color="#8c3319" width="80%" />
        </div>
        <div className="col-6 mt-8 flex align-items-center justify-content-center">
          <Formik
            classname="login-form"
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            /**
             * Submits the login form and handles the response.
             *
             * Signs in with email and password, then fetches the user's information
             * from the server and stores the token and user information in the
             * context state. If the sign in fails, it sets an error message to
             * be displayed on the form.
             *
             * @param {Object} values - The form values.
             * @param {string} values.email - The user's email.
             * @param {string} values.password - The user's password.
             *
             * @return {Promise<void>}
             */
            onSubmit={async ({ email, password }) => {
              try {
                // Sign in with email and password
                await auth.signInWithEmailAndPassword(email, password)

                const currentUser = auth.currentUser

                const idToken = await currentUser.getIdToken()
                const headers = {
                  Authorization: `Bearer ${idToken}`,
                }
                const response = await axios.get(
                  `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/users/${currentUser.uid}`,
                  {
                    headers,
                  }
                )

                if (response.status !== 200) {
                  throw new Error(response?.response?.error || "Server error")
                }
                const { token, user } = response?.data?.response.data || {}

                setToken(token)
                setUser(user)
                gaTrackingParams.event = "login"
                gaTracking(gaTrackingParams)
                navigate(ROUTES.Home, {
                  state: { fromLogin: true },
                })
              } catch (error) {
                gaTrackingParams.event = "login failed"
                gaTracking(gaTrackingParams)
                setAuthError(
                  <div className="gap-1 w-20rem">
                    <p className="m-0" style={{ color: "#ce0000" }}>
                      "Oops! That username or password doesn't match.{" "}
                    </p>
                    <p style={{ color: "#ce0000" }} className="m-0">
                      Please try again"
                    </p>
                  </div>
                )
                console.log(error)
              }
            }}
          >
            {(formik) => {
              const { errors, touched, setFieldValue } = formik

              return (
                <Form>
                  <Toast ref={toast} />
                  <Card className="flex flex-column align-items-center justify-content-center gap-5 p-card-login	h-full  w-30rem lg:ml-8  animate-slide-up">
                    <h2 className="text-center mt-4">Sign Up</h2>
                    <div className="flex flex-column gap-4 mt-6">
                      <div
                        className=" flex flex-column"
                        style={{ position: "relative" }}
                      >
                        <label
                          className="font-semibold text-gray-900	py-2 "
                          htmlFor="email"
                        >
                          Email:
                        </label>
                        <InputText
                          id="email"
                          name="email"
                          type="email"
                          placeholder="e.g. user@example.com"
                          onChange={({ target: { value } }) =>
                            setFieldValue("email", value)
                          }
                          value={formik.values.email}
                        />

                        {errors.email && touched.email && (
                          <div style={{ color: "#ce0000" }}>{errors.email}</div>
                        )}
                      </div>

                      <div className=" flex flex-column ">
                        <label
                          className="font-semibold text-gray-900	py-2 "
                          htmlFor="password"
                        >
                          Password:{" "}
                        </label>
                        <InputText
                          id="password"
                          name="password"
                          type="password"
                          onChange={({ target: { value } }) =>
                            setFieldValue("password", value)
                          }
                          value={formik.values.password}
                        />

                        {errors.password && touched.password && (
                          <div style={{ color: "#ce0000" }}>
                            {errors.password}
                          </div>
                        )}
                        {authError && (
                          <div style={{ color: "#ce0000" }}>{authError}</div>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-content-end mt-2">
                      <p className="flex align-items-center justify-content-center ">
                        Forgot your password?{" "}
                        <Button
                          type="button"
                          className="p-button-text p-button-plain -ml-3font-semibold"
                          style={{
                            background: "none",
                            border: "none",
                            textDecoration: "underline",
                            color: "var(--secondary-color)",
                          }}
                          onClick={() => setVisible(true)}
                          to="/"
                        >
                          Reset it here!
                        </Button>
                      </p>
                      {/* Reset Password Modal */}
                      <Dialog
                        visible={visible}
                        modal
                        onHide={() => setVisible(false)}
                      >
                        <ResetPasswordDialog
                          hide={() => setVisible(false)}
                          onResetPassword={handleResetPassword}
                          recoverEmail={recoverEmail}
                          setRecoverEmail={setRecoverEmail}
                        />
                      </Dialog>
                    </div>
                    <div className="flex justify-content-center ">
                      <Button className="mt-4 p-button-primary" type="submit">
                        Login
                      </Button>
                    </div>
                  </Card>
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}
