import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Layout from "../layout"
import RoutesBuilder from "../components"
import { auth } from "../firebase"
import Login from "../pages/login/login"
import { UserProvider } from "../context/userContext"
import { LoaderMedium } from "../components/Loader/loaders"

function App() {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    /**
     * This useEffect hook is used to listen for changes in the authentication
     * state of the user. When the user is logged in, it will try to get a new
     * id token and set the user state to the auth user. When the user is logged
     * out, it will set the user state to null. The loading state is set to false
     * once the user state is set to something other than null.
     */
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        try {
          await authUser.getIdToken(true)
          setUser(authUser)
        } catch (error) {
          console.error("Error al renovar el token:", error)
          setUser(null)
        }
      } else {
        setUser(null)
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  /**
   * This If statement is used to display a loading screen while the user state
   * is being set.
   */
  if (loading) {
    return (
      <div
        className="w-full h-screen flex justify-content-center align-items-center"
        style={{ paddingBottom: "10rem" }}
      >
        <LoaderMedium />
      </div>
    )
  }

  return (
    <UserProvider>
      <Router>
        <Layout>
          {user ? (
            <RoutesBuilder />
          ) : (
            <Routes>
              <Route path="*" element={<Login />} />
            </Routes>
          )}
        </Layout>
      </Router>
    </UserProvider>
  )
}

export default App
