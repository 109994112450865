import { createContext, useState, useContext, useEffect } from "react"

const UserContext = createContext()

export const useUserContext = () => useContext(UserContext)

/**
 * UserProvider manages the authentication state of the user by providing
 * context to its children. It initializes the user and token states from
 * local storage and offers functions to update these states. It also
 * maintains a loading state to indicate whether the user data is being
 * loaded. The context value includes the current token, user, loading
 * state, and the functions to set the token and user.
 *
 * @param {object} children - The child components that will consume the
 * UserContext.
 * @returns {JSX.Element} A provider component that supplies the user
 * context to its children.
 */
export const UserProvider = ({ children }) => {
  const [token, setTokenState] = useState(null)
  const [user, setUserState] = useState(null)
  const [isLoading, setIsLoading] = useState(true) // Initially loading

  useEffect(() => {
    const savedToken = localStorage.getItem("token")
    const savedUser = localStorage.getItem("user")

    if (savedToken) setTokenState(savedToken)
    if (savedUser) setUserState(JSON.parse(savedUser))

    setIsLoading(false) // Change to false after loading data
  }, [])

  /**
   * Sets the token state to the given newToken and persists the change to
   * local storage. If newToken is null, the token is removed from local
   * storage.
   *
   * @param {string} newToken - The new token value to set the state to.
   */
  const setToken = (newToken) => {
    setTokenState(newToken)
    if (newToken) {
      localStorage.setItem("token", newToken)
    } else {
      localStorage.removeItem("token")
    }
  }

  /**
   * Sets the user state to the given newUser and persists the change to
   * local storage. If newUser is null, the user is removed from local
   * storage.
   *
   * @param {object} newUser - The new user object to set the state to.
   */
  const setUser = (newUser) => {
    setUserState(newUser)
    if (newUser) {
      localStorage.setItem("user", JSON.stringify(newUser))
    } else {
      localStorage.removeItem("user")
    }
  }

  return (
    <UserContext.Provider value={{ token, setToken, user, setUser, isLoading }}>
      {children}
    </UserContext.Provider>
  )
}
