import React from "react"
import { Button } from "primereact/button"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
const DonorStatus = ({ countDonors }) => {
  const navigate = useNavigate()

  if (countDonors === 0) {
    return (
      <div className="flex flex-column align-items-center">
        <Button
          onClick={() => navigate("/form")}
          className="mt-6 mr-4 p-button-disabled"
          disabled
        >
          New Order
        </Button>
        <p className="mr-4">No Donors Available</p>
      </div>
    )
  }

  return (
    <div>
      <Button
        onClick={() => navigate("/form")}
        className="my-6 mr-4 gap-2 p-button-primary"
        icon="pi pi-plus"
      >
        New Order
      </Button>
    </div>
  )
}
DonorStatus.propTypes = {
  countDonors: PropTypes.number,
}
export default DonorStatus
