import app from "firebase/compat/app"

import "firebase/compat/auth"
import "firebase/compat/firestore"

// Your web app's Firebase configuration
const firebaseConfigDev = {
  apiKey: "AIzaSyCDwXCivMER4aYNvDNEboastR1hFaUyRRs",
  authDomain: "lantana-mlsp-dev.firebaseapp.com",
  projectId: "lantana-mlsp-dev",
  storageBucket: "lantana-mlsp-dev.appspot.com",
  messagingSenderId: "697900234335",
  appId: "1:697900234335:web:260d37cee8f0cab893cdea",
}

const firebaseConfigProd = {
  apiKey: "AIzaSyCb0UUqRfFaeARaByIYafjcL2R9B3Ba9Ig",
  authDomain: "lantana-mlsp-prod.firebaseapp.com",
  projectId: "lantana-mlsp-prod",
  storageBucket: "lantana-mlsp-prod.firebasestorage.app",
  messagingSenderId: "478213531371",
  appId: "1:478213531371:web:49d270a1521507d7ea80ae"
}

// Initialize Firebase with a "default" Firebase project
const firebaseConfig = process.env.REACT_APP_ENV === 'Production' ? firebaseConfigProd : firebaseConfigDev
app.initializeApp(firebaseConfig)

const auth = app.auth()

export { auth }
