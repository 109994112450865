import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"
import GLOBAL from "../../config/variables"
import { useUserContext } from "../../context/userContext"
import getDate from "../../Helpers/getDate"
import ROUTES from "../../components/routesConstants"
//PR
import { Menubar } from "primereact/menubar"
import { auth } from "../../firebase"
import { Dropdown } from "primereact/dropdown"
//Loader
import { LoaderRotation } from "../../components/Loader/loaders"
//Image Default
import userDoctor from "./user-doctor.svg"

export default function Header() {
  const [options, setOptions] = useState(null)
  const [facility, setFacility] = useState("")
  const { token, user } = useUserContext()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  // Fetch facility (only when user and token are available)
  useEffect(() => {
    if (!user || !token) return // Ensure that user and token are available
    const fetchFacility = async () => {
      try {
        const response = await axios.get(
          `${GLOBAL.ORDER_MANAGEMENT_ENDPOINT}/organizations/${user.organization_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        if (response.status === 200) {
          setFacility(response.data.response.data)
          setLoading(false)
        }
      } catch (error) {
        navigate(ROUTES.Error, {
          state: { error: error.message },
        })
      }
    }

    fetchFacility()
  }, [token, user, navigate])

  // If user is not available, render loading or placeholder
  if (!user || !token) {
    return (
      <header className="flex flex-column header align-items-center">
        <div className="w-full h-5rem shadow-2 flex justify-content-center align-items-center"></div>
      </header>
    )
  }

  const optionsDropDown = [
    {
      name: "Sign Out",
      icon: "pi pi-fw pi-power-off",
      /**
       * Sign out of Firebase and navigate to login page
       */
      command: () => {
        auth
          .signOut()
          .then(() => {
            navigate(ROUTES.Login, { replace: true })
          })
          .catch((error) => {
            alert(error)
          })
      },
    },
  ]
  /**
   * A template function that renders a single dropdown option.*/
  const optionTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2" onClick={option.command}>
        <i className={option.icon}></i>
        <span>{option.name}</span>
      </div>
    )
  }

  const start = (
    <div className="ml-3 flex align-items-center gap-3">
      <div
        className="w-3rem h-3rem shadow-2"
        style={{
          backgroundColor: "var(--secondary-color)",
          borderRadius: "50%",
        }}
      ></div>
      <Link className="no-underline" to={ROUTES.Home}>
        <h3>MatchLink</h3>
      </Link>
    </div>
  )

  const end = (
    <div className="flex align-items-center gap-2 h-full mr-4">
      <div
        className="w-3rem h-3rem"
        style={{
          borderRadius: "50%",
          backgroundColor: "var(--white-color)",
        }}
      >
        <img className="w-full h-full" src={userDoctor} alt="User" />
      </div>
      <div>
        <p className="m-0">
          <strong>{`${user.user_name_first} ${user.user_name_last}`}</strong>
        </p>
        <p className="m-0">{`${user.user_role_value}`}</p>
      </div>
      <Dropdown
        className="w-1rem border-none mr-4 header-dropdown"
        style={{ backgroundColor: "var(--primary-color)" }}
        options={optionsDropDown}
        value={options}
        optionLabel="name"
        itemTemplate={optionTemplate}
      />
    </div>
  )

  return (
    <header className="flex flex-column header align-items-center">
      <Menubar className="w-full h-5rem shadow-2" start={start} end={end} />
      <div className="w-full h-4rem -mt-3 flex align-items-center gap-2 border-round-xl shadow-2">
        {loading ? (
          <div className="mt-3 ml-4">
            <LoaderRotation />
          </div>
        ) : (
          <section className="flex justify-content-between align-items-center w-full pt-3">
            <div className="flex align-items-center gap-2">
              <h5
                className=" ml-6 h5Header"
                style={{ color: "var(--primary-color)" }}
              >
                {facility.organization_name}
              </h5>
              <div>
                <div>
                  <p className="m-0 flex gap-2">
                    <strong className="text-gray-500">Last update: </strong>
                    {facility?.donor_latest_ingestion_datetime
                      ? getDate(facility.donor_latest_ingestion_datetime)
                      : "No donor data ingested yet."}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex align-items-center justify-content-end mr-6  gap-4">
              {facility?.count_donors === 0 ? (
                <div className="flex align-items-center justify-content-end">
                  <p className="m-0 3 flex gap-2 text-red-500">
                    No Donors found
                  </p>
                </div>
              ) : (
                <p className="m-0  flex gap-2">
                  <strong>Donor Count:</strong>
                  {facility.count_donors.toLocaleString("en-US")} Donors
                </p>
              )}

              {facility?.count_patients === 0 ? (
                <div className="flex align-items-center justify-content-end">
                  <p className="m-0 3 flex gap-2 text-red-500">
                    No Patients found
                  </p>
                </div>
              ) : (
                <p className="m-0  flex gap-2">
                  <strong>Patient Count:</strong>
                  {facility.count_patients} Patients
                </p>
              )}
            </div>
          </section>
        )}
      </div>
    </header>
  )
}
