import React from "react"

/**
 * Converts a given date string to a local date and time string in "en-US" format.
 * The date is adjusted to UTC and then formatted to include year, month, day,
 * hour, and minute in a 12-hour format with AM/PM.
 *
 * @param {string} date - The date string to be converted.
 * @returns {JSX.Element} A JSX element containing the formatted local date string.
 */
export default function getDate(date) {
  const utcDate = new Date(date + "Z")

  const localDateString = utcDate.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit", // Uncomment this line to include seconds
    hour12: true, // true for 12-hour format
  })
  return <div>{localDateString}</div>
}
